import { FillLayer, LineLayer } from 'mapbox-gl'
import React, { useMemo, useState } from 'react'
import { Layer, Marker, Popup, Source } from 'react-map-gl'
import { PolygonGeoJSON, Waypoint } from '../@types/types'
import {FeatureCollection,Feature,Polygon, MultiPoint} from 'geojson'
import { SymbolLayer } from 'mapbox-gl'
import { Box, Button, Typography } from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place';


type SelectedAreaCoords = {
    areaCoords : Array<Waypoint>,
    waypointsCoords : Array<Waypoint>,
    setWaypointsCoords : React.Dispatch<React.SetStateAction<Array<Waypoint>>>,
    setAreaCoords: React.Dispatch<React.SetStateAction<Array<Waypoint>>>,
    setIsNeutral: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectedArea = ({areaCoords = [],waypointsCoords = [],setWaypointsCoords,setAreaCoords,setIsNeutral}: SelectedAreaCoords) => {
  const [popupVisible,setPopupVisible] = useState<boolean>(false) 
  const [popupIdx,setPopupIdx] = useState<number|null>(null) 
  /*
    //Test Coordinates
    "coordinates": [[
                       [-67.13734351262877, 45.137451890638886],
                       [-66.96466, 44.8097],
                       [-68.03252, 44.3252],
                       [-69.06, 43.98],
                       [-70.11617, 43.68405],
                       [-70.64573401557249, 43.090083319667144],
                       [-70.75102474636725, 43.08003225358635],
                       [-70.79761105007827, 43.21973948828747],
                       [-70.98176001655037, 43.36789581966826],
                       [-70.94416541205806, 43.46633942318431],
                       [-71.08482, 45.3052400000002],
                       [-70.6600225491012, 45.46022288673396],
                       [-70.30495378282376, 45.914794623389355],
                       [-70.00014034695016, 46.69317088478567],
                       [-69.23708614772835, 47.44777598732787],
                       [-68.90478084987546, 47.184794623394396],
                       [-68.23430497910454, 47.35462921812177],
                       [-67.79035274928509, 47.066248887716995],
                       [-67.79141211614706, 45.702585354182816],
                       [-67.13734351262877, 45.137451890638886]
                     ]],
    */
    const areaGeoJson : PolygonGeoJSON = {
        type: "FeatureCollection",
        features: [{
           "type": "Feature",
            "properties": {
                  "category": "",
                },
            "geometry": {
                  "type": "Polygon",
                  "coordinates": [areaCoords.map((coordinate) =>[coordinate.longitude,coordinate.latitude])]
             }
        }]
    }
        // Create GeoJSON for the points layer
        /*
    const areaPointsGeoJson: FeatureCollection = {
            type: "FeatureCollection",
            features: areaCoords.map((coordinate) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [coordinate.longitude, coordinate.latitude]
                },
                properties: {}
            }))
        }
            */
    const waypointsGeoJson: FeatureCollection = {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: waypointsCoords.map((coordinate) => [
                        coordinate.longitude,
                        coordinate.latitude,
                    ]),
                },
                properties: {},
            },
        ],
    };
    /*
    const waypointsPointsGeoJson: FeatureCollection = {
        type: "FeatureCollection",
        features: waypointsCoords.map((coordinate) => ({
            type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [
                        coordinate.longitude,
                        coordinate.latitude
                    ]
                },
                properties: {}
        })),

    }
    */
    const polygonFillLayer : FillLayer= {
        id: "draw-area-layer",
        source:"",
        type: 'fill',
        paint:{
            'fill-color':'red',
            'fill-opacity':0.5,
        }
    }
    /*
    const areaCoordsLayer : SymbolLayer= {
        id: 'draw-area-points',
        source: "points-source",
        type: 'symbol',
        layout: {
            'icon-image': 'marker-15', // You can use built-in Mapbox icons or add your own
            'icon-size': 1.5
        }
      };
      const waypointsLayer : SymbolLayer= {
        id: 'waypoints-points',
        source: "",
        type: 'symbol',
        layout: {
            'icon-image': 'marker-15', // You can use built-in Mapbox icons or add your own
            'icon-size': 1
        },
        paint: {
            'icon-color': '#FF0000' // Red
        },
      };
      */
      const lineLayerStyle : LineLayer= {
        id: "waypoints-lines",
        source: "waypoints-source",
        type: "line",
        paint: {
          'line-color': '#008000',
          'line-width': 2
        
        }
    }

const waypointsPins = useMemo(() => {return waypointsCoords.map((waypoint, index) => (
  <>
    <Marker
      key={`waypoint-marker-${index}`}
      longitude={waypoint.longitude}
      latitude={waypoint.latitude}
      draggable={true}
      onDrag={(e) => {
        setPopupVisible(false)
        setIsNeutral(true)
        // Update the waypoint's coordinates during drag
        const updatedWaypoints = [...waypointsCoords];
        updatedWaypoints[index] = {
          ...waypoint,
          longitude: e.lngLat.lng,
          latitude: e.lngLat.lat,
        }; //At that index, we change that particular waypoint
        setWaypointsCoords(updatedWaypoints)
      }}
      onDragEnd={(e) => {
        // Optionally log or process final coordinates here
        setPopupVisible(false)
        console.log(`New coordinates for waypoint ${index}:`, e.lngLat);
      }}
      onClick={(e) => {
        e.originalEvent.stopPropagation();
        setIsNeutral(true);
        //setPopupInfo({latitude: waypoint.latitude,longitude: waypoint.longitude});
        setPopupIdx(index)
        setPopupVisible(true);
        console.log(`Selected Waypoint ${index}`)
      }}
      anchor='bottom'
      style={{cursor:'pointer'}}
    >
      <Box
      sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
      <Typography
      fontSize={10}
      >
        W{index}
      </Typography>
      <Typography  onClick = {() => {
        setIsNeutral(true)
        console.log("Removing Waypoint")
        console.log(waypointsCoords)
        const updatedWaypoints = waypointsCoords.filter((_, i) => i !== index);
        setWaypointsCoords(updatedWaypoints);
      }} 
      sx={{cursor: 'pointer',fontSize:'10px'}}
      >
        X
      </Typography>
      </Box>
      <PlaceIcon />
    </Marker>
    {popupVisible && popupIdx == index && (
      <>
      <Popup
        longitude={waypoint.longitude}
        latitude={waypoint.latitude}
        onClose={() =>{setIsNeutral(false);setPopupVisible(false);}}
      >
        Waypoint {index}
        <div>
        latitude: {waypoint.latitude} <br/>
        longitude: {waypoint.longitude} <br/>
        </div>
      </Popup></>)}
    </>
  ))},[waypointsCoords, waypointsGeoJson,popupIdx])
    //console.log("rendering polygon of coordinates: ",areaCoords)
/*<Source type="geojson" data={waypointsPointsGeoJson}>
    <Layer {...waypointsLayer} />
</Source> */
const areaPins = useMemo(() => {return areaCoords.map((areaCoord, index) => (
    <Marker
      key={`area-coord-marker-${index}`}
      longitude={areaCoord.longitude}
      latitude={areaCoord.latitude}
      draggable={true}
      onDrag={(e) => {
        // Update the waypoint's coordinates during drag
        const updatedAreaCoords = [...areaCoords];
        updatedAreaCoords[index] = {
          ...areaCoord,
          longitude: e.lngLat.lng,
          latitude: e.lngLat.lat,
        }; //At that index, we change that particular waypoint
        setAreaCoords(updatedAreaCoords)
      }}
      onDragEnd={(e) => {
        // Optionally log or process final coordinates here
        console.log(`New coordinates for area coord ${index}:`, e.lngLat);
      }}
    >
      <PlaceIcon sx={{'color':'brown'}} />
    </Marker>
  ))},[areaCoords])

return (
<>
<Source type="geojson" data={areaGeoJson}>
    <Layer {...polygonFillLayer}/>
</Source>
{areaPins}
{waypointsPins}
<Source type="geojson" data={waypointsGeoJson}>
    <Layer {...lineLayerStyle} />
</Source>
</>
)

}    
   

export default SelectedArea