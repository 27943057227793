import React from 'react'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import SideBar from '../components/SideBar'
import { Card, CardContent, CardHeader, Container, stepClasses } from '@mui/material';


const Introduction = () => {
	return (
        <Container maxWidth={false}>
        <Box sx={{ display: "flex", flexGrow: 0, flexBasis: 0 }}>
          <SideBar />
          <Box>
            <CssBaseline />
      
            <Typography variant="h1">Introduction</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              This application is designed to facilitate data transmission to CAAS and MPA. Below, you will find summaries of the available categories for easy access to the corresponding links.
            </Typography>
      
            <Typography variant="h3">CFMS Connection</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              If you are accessing this via the <Typography component="span" fontWeight="bold">DJI controller</Typography>, this feature is for you. Information entered on this page utilizes the DJI Cloud API to transmit essential drone data directly to the CFMS endpoint managed by CAAS.
            </Typography>
      
            <Typography variant="h3">Flighthub Integration</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              For direct transmission to our MQTT broker, use this tab to input the required details and click 'Submit'. This feature seamlessly integrates telemetry data sent directly from DJI Flighthub.
            </Typography>
      
            <Typography variant="h3">AIS Dashboard</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Access real-time ship data for monitoring active drones. Export KMZ files directly from here, which are compatible with DJI Flighthub.
            </Typography>
      
            <Typography variant="h3">My Profile</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              View authenticated drones linked to your profile. If the desired serial number is missing, please contact the administrator for assistance.
            </Typography>
      
            <Typography variant="h3">Help</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Find answers to frequently asked questions and solutions to common issues.
            </Typography>
      
          </Box>
        </Box>
      </Container>
      
  );

}
export default Introduction;