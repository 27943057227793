import { Button } from '@mui/material'
import React from 'react'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import MarkunreadMailboxTwoToneIcon from '@mui/icons-material/MarkunreadMailboxTwoTone';
import { WaypointSelectorControlType,DrawAreaSelectorControlType } from '../@types/types';

export const WaypointSelectorControl = ({isWaypointSelector,setIsWaypointSelector,setIsDrawAreaSelector}:WaypointSelectorControlType) => {
    if(isWaypointSelector){
      return(
        <Button onClick={() => {setIsWaypointSelector(false);setIsDrawAreaSelector(false);}}>
          <ControlPointTwoToneIcon/>
        </Button>
      )
    }
    return (
    <Button onClick={() => {setIsWaypointSelector(true);setIsDrawAreaSelector(false);}}>
        <ControlPointIcon />
    </Button>)
  }

export const DrawAreaSelectorControl = ({isDrawAreaSelector,setIsWaypointSelector,setIsDrawAreaSelector}:DrawAreaSelectorControlType) => {
if(isDrawAreaSelector){
    return(
    <Button onClick={() => {setIsWaypointSelector(false);setIsDrawAreaSelector(false);}}>
        <MarkunreadMailboxTwoToneIcon/>
    </Button>)
}
return(
    <Button onClick={() => {setIsWaypointSelector(false);setIsDrawAreaSelector(true);}}>
        <MarkunreadMailboxOutlinedIcon />
    </Button>)
}
